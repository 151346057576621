<template>
  <div id="chart-wrap">
    <CButton color="warning" size="sm" @click="replaceData">変更</CButton>
    <CButton color="warning" size="sm" @click="series = []">clear</CButton>

    <select  id="sel1" v-model="dayCount" autocomplete="device kind" class="form-control ml-1">
      <option value="1">1日</option>
      <option value="7">7日</option>
      <option value="30">30日</option>
      <option value="90">90日</option>
    </select>

    <input type="date" v-model="fromDate">

    {{height}}
    <apexchart  width="100%" :height="chartOptions.chart.height" type="bar" :options="chartOptions"
               :series="series"></apexchart>


    <div class="legend">
      <!-- <div class="pretty p-svg p-smooth"> -->

          <template v-for="(val, key)  in colorList" >
            <div class="chart-regend mr-2 mb-5"><div class="cart-regend-item" :style="{border:'solid 4px '+ val}"><label style="font-size: 0.7rem;margin: 3px;font-weight: bold">{{ key }}</label></div></div>
          </template>
<!--      <div class="chart-regend"><div class="cart-regend-item" style="border:solid 4px #F64E09;"><label style="font-size: 0.7rem;">1.5</label></div></div>-->
<!--      <div class="chart-regend"><div class="cart-regend-item" style="border:solid 4px #F9C308;"><label style="font-size: 0.7rem;">1.5</label></div></div>-->
<!--      <div class="chart-regend"><div class="cart-regend-item" style="border:solid 4px #FAFF09;"><label style="font-size: 0.7rem;">1.5</label></div></div>-->

<!--      <div class="chart-regend"><div class="cart-regend-item" style="border:solid 4px #C3FF28;"><label style="font-size: 0.7rem;">1.5</label></div></div>-->
<!--      <div class="chart-regend"><div class="cart-regend-item" style="border:solid 4px #5BFF27;"><label style="font-size: 0.7rem;">1.5</label></div></div>-->
<!--      <div class="chart-regend"><div class="cart-regend-item" style="border:solid 4px #2BFF87;"><label style="font-size: 0.7rem;">1.5</label></div></div>-->
<!--      <div class="chart-regend"><div class="cart-regend-item" style="border:solid 4px #31C0FF;"><label style="font-size: 0.7rem;">1.5</label></div></div>-->
<!--      <div class="chart-regend"><div class="cart-regend-item" style="border:solid 4px #274EC1;"><label style="font-size: 0.7rem;">1.5</label></div></div>-->

<!--      <div class="chart-regend"><div class="cart-regend-item" style="border:solid 4px #2701FF;"><label style="font-size: 0.7rem;">1.5</label></div></div>-->
<!--      <div class="chart-regend"><div class="cart-regend-item" style="border:solid 4px #8547FF;"><label style="font-size: 0.7rem;">1.5</label></div></div>-->
<!--      <div class="chart-regend"><div class="cart-regend-item" style="border:solid 4px #8500FF;"><label style="font-size: 0.7rem;">1.5</label></div></div>-->
<!--      <div class="chart-regend"><div class="cart-regend-item" style="border:solid 4px #520053;"><label style="font-size: 0.7rem;">1.5</label></div></div>-->

    </div>
<!--    <div :key="index" v-for="(val, key)  in colorList" class="row mb-1">-->
<!--      <div v-bind:style="{ backgroundColor: val }">{{ key }}</div>-->
<!--    </div>-->

  </div>


</template>

<script>

let colorList={
 // "0.25":"#BE1F05",
 // "0.50":"#F60003",
 // "0.75":"#F64E09",
 // "1.00":"#F9C308",
 // "1.25":"#FAFF09",
 // "1.50":"#C3FF28",
 // "1.75":"#5BFF27",
 // "2.00":"#2BFF87",
 // "2.50":"#31C0FF",
 // "3.00":"#274EC1",
 // "3.50":"#2701FF",
 // "4.00":"#8547FF",
 // "4.50":"#8500FF",
 // "5.00":"#520053",
  "0.25":"#DCDC80",
  "0.50":"#F0ED83",
  "0.75":"#98B54F",
  "1.00":"#66FF33",
  "1.25":"#00FF99",
  "1.50":"#00B050",
  "1.75":"#00B0B0",
  "2.00":"#3366CC",
  "2.50":"#0000FF",
  "3.00":"#9966FF",
  "3.50":"#9900FF",
  "4.00":"#FFC000",
  "4.50":"#FF962A",
  "5.00":"#FF9999",
}
let valueList=[
  "0.25",
  "0.50",
  "0.75",
  "1.00",
  "1.25",
  "1.50",
  "1.75",
  "2.00",
  "2.50",
  "3.00",
  "3.50",
  "4.00",
  "4.50",
  "5.00",
];


import moment from "moment";

function dhm(second) {
  var result = "";
  var d = Math.floor(second / (60 * 60 * 24));

  if (d > 0) {
    result += (d + "日")
    second = second % (60 * 60 * 24);
  }
  var h = Math.floor(second / (60 * 60));
  if (h > 0) {
    result += (h + "時間");
    second = second % (60 * 60);
  }
  var m = Math.floor(second / (60));
  if (m > 0) {
    result += (m + "分");
  }
  console.log(d, h, m)
  return result + "間";
}

export default {
  name: "App",
  data() {

    return {
      colorList,
      series: [],
      dayCount:7,
      patientId:14,
      fromDate:moment("2021-03-14").format("YYYY-MM-DD"),
      height:300
    }
  },
  watch: {
    dayCount() {
      this.getData();
    },
    fromDate(){
      this.getData();
    }
  },
  methods:{
    replaceData(){
      this.getData();
    },
    getData(){
      this.series = [];
      this.axios.get(`/api/measureValue/getAllChart/${this.patientId}/${this.dayCount}/${this.fromDate}`)
          .then(response => {
            this.series = response.data.result;
          }).catch(error => {
          })
          .finally(() => {
          });
    },
    getSeries(){
      let list = [];
      for(let i=0;i<24;i++){
        let data = []
        for(let j =0;j<24;j++){
          let value = valueList[Math.floor( Math.random() * valueList.length )]
          data.push({"x": "2017-02-1"+j,"y": Math.floor( Math.random() * 60*60 ),"v":value});
        }
        list.push({
          name: (i+1).toString(),
          data: data
        });
      }
      console.log("getSeries",JSON.stringify(list));
      return list;
    },
  },
  mounted(){
      // this.series = this.getSeries();
     this.getData();
     this.chartOptions.yaxis.labels.show=false;
  },
  computed:{

    chartOptions(){
      let dCount = this.dayCount;
      return  {
        chart: {
          type: 'bar',
          height: 200,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },animations: {
            enabled: false,
          }
       },
        title: {
          text: "酸素流量",
          align: 'center',
          offsetX: 0,
          offsetY: 5,
          floating: true,
          style: {
            fontSize:  '17px',
            fontWeight:  'bold',
            // fontFamily:  undefined,
            color:  '#263238'
          },
        },
       dataLabels: {
        enabled: false,
       },
       stroke:{
        show: false,
        curve: 'straight',
        width: 2,
        colors: ["#000"]
       },
        // responsive: [{
        //   breakpoint: 480,
        //   options: {
        //     legend: {
        //       show:false,
        //       position: 'bottom',
        //       offsetX: -10,
        //       offsetY: 0
        //     }
        //   }
        // }],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '100%',
            barHeight: '100%',
          },
        },
        tooltip: {
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            console.log("series",JSON.stringify(series));
            console.log("w",w);

            let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            return (
                '<div class="card-tooltip-container">'+
                // '  <div class="card-tooltip-header">'+w.globals.labels[dataPointIndex]+'</div>'+
                '  <div class="card-tooltip-header">'+data.v+'</div>'+
                // '      <div class="card-tooltip-body">'+series[seriesIndex][dataPointIndex]+'</div>'+
                // '      <div class="card-tooltip-body">'+data.v+'</div>'+
                // '      <div class="card-tooltip-body">'+data.x+'</div>'+
                // '      <div class="card-tooltip-body">'+data.y+'</div>'+
                '      <div class="card-tooltip-body">'+data.os+'から</div>'+
                // '      <div class="card-tooltip-body">oe'+data.oe+'</div>'+
                '      <div class="card-tooltip-body">'+dhm(data.oy)+'</div>'+
                '</div>'
            );
          }
        },
        xaxis: {
          type: "date",　//日以外
              // type: "datetime",　 //時間
              labels: {
              show: true,
                rotate: -20,
                // format: 'yyyy/MM/dd', //日以外
                //format: 'yyyy/MM/dd HH:mm' 日
                /**
                 * Allows users to apply a custom formatter function to xaxis labels.
                 *
                 * @param { String } value - The default value generated
                 * @param { Number } timestamp - In a datetime series, this is the raw timestamp
                 * @param { object } contains dateFormatter for datetime x-axis
                 */
                formatter: function (value, timestamp, opts) {
                  if (dCount == 1) {
                    return moment(value).format('H') + "h";
                  } else {
                    let day = parseInt(moment(value).format("DD"));
                    if (dCount == 30) {
                      if (day % 2 == 0) {
                        return moment(value).format("M/DD");
                      } else {
                        return "";
                      }
                    } else if (dCount == 90) {
                      if (day % 6 == 0) {
                        return moment(value).format("M/DD");
                      } else {
                        return "";
                      }
                    }
                    return moment(value).format("M/DD");
                  }
            }
          }
        },
        yaxis: {
          min: 0,
              // max: 86400,//秒
              max: dCount == 1 ? 3600 : 86400,
              tickAmount: dCount == 1 ? 24 : 12,//24時間
              labels:{
                show: dCount != 1,
                formatter: function(val, index) {
                  let hour = (val / 60 / 60);
                  // if(hour % 2 == 1){
                    return (val / 60 / 60)+"h";
                  // return val;
            }
          }
        },
        legend: {
          show:false,
        },
        fill: {
          opacity: 1
        }
      };
    }
  }
};
</script>
<style>
.chart-regend{
  position: relative;
  display: inline-block;
  margin-right: 0.5em;
  white-space: nowrap;
  line-height: 0.7;
  margin-top: -20px;
  margin: 0 auto;
}
.cart-regend-item{
  /*padding:5px 3px;*/
  /*border:solid 1px rgb(180, 179, 179);*/
  vertical-align: middle;
  text-align: center;
  margin:0;
  /*position: relative;*/
  /*line-height: 15px;*/
}
#chart-wrap {
  max-width: 800px;
  position: relative;
  margin: 35px auto;
}

#chart {
  padding-bottom: 30px;
  max-width: 800px;
}

.legend {
  max-width: 800px;
  /*left: 80px;*/
  bottom: 20px;
  position: relative;
  text-align: right;
}
.card-tooltip-container{
  border: solid 1px sandybrown;
  width:250px;
  text-align: center;
  border-radius: 5%;
}
.card-tooltip-header{
  background-color: sandybrown;
  text-align: center;
  color: white;
  font-weight: bold;
}
.card-tooltip-body{
  text-align: left;
}

</style>
